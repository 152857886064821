import React, { useContext, useEffect, useState } from "react";
import { PurchaseContext } from "../Context/PurchaseContext";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { VendorContext } from "../Context/VendorContext";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import axios from "axios";
import config from "../../Config";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const SalesReport = () => {
  const [filterShow, setFilterShow] = useState(false);
  const { logout } = useContext(AuthContext);
  const [filterInputs, setFilterInputs] = useState({
    startDate: "",
    endDate: "",
    party: "",
  });
  const [sales, setSales] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const [view, setView] = useState(false);
  const token = localStorage.getItem("token");
  const [disable,setDisable] = useState(false);

  const fetchSales = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/get-sales`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setSales(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };
  useEffect(() => {
    fetchSales();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterInputs((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const filterSearch = () => {
    const startDate = filterInputs.startDate
      ? new Date(filterInputs.startDate)
      : null;
    const endDate = filterInputs.endDate
      ? new Date(filterInputs.endDate)
      : null;
    if (startDate && endDate && filterInputs.party !== "") {
      return sales.filter((item) => {
        const itemDate = new Date(item.salesDate);

        return (
          (!startDate || itemDate >= startDate) &&
          (!endDate || itemDate <= endDate) &&
          item.party === filterInputs.party
        );
      });
    } else if (startDate && endDate) {
      return sales.filter((item) => {
        const itemDate = new Date(item.salesDate);

        return (
          (!startDate || itemDate >= startDate) &&
          (!endDate || itemDate <= endDate)
        );
      });
    } else if (filterInputs.party !== "") {
      return sales.filter((item) => {
        return item.party === filterInputs.party;
      });
    } else {
      return sales;
    }
  };

  const tableItemHead = [
    {
      name: 'SNo',
      selector: (row, index) => index + 1, 
      sortable: false,
      width: '70px',
    },
    {
      name: "Item Name",
      selector: (row) => row.itemName,
      sortable: true,
    },
    {
      name: "Cetegory",
      selector: (row) => row.categoryId.categoryname,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
  ];

  const tableHead = [
    {
      name: "Party",
      selector: (row) => row.partyId.partyName,
      sortable: true,
    },
    {
      name: "Sales Date",
      selector: (row) => row.salesDate && row.salesDate.slice(0, 10),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <button type="button" onClick={() => handleView(row)}>
          <img src="/img/icons/view.svg" alt="img" />
        </button>
      ),
      sortable: true,
    },
  ];

  const handleView = (row) => {
    setView(true);
    setPurchaseData(row);
  };

  const generatePDF = () => {
    setDisable(true);
    const doc = new jsPDF();
    const columns = ["Party", "Sales Date"];

    const rows = sales.map((data) => [data.partyId.partyName, data.salesDate.slice(0, 10)]);
    doc.text("Sales Report", 14, 15);
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
      theme: "grid",
    });
    doc.save("sales_report.pdf");
    setDisable(false);
    toast.success("PDF Downloaded!");
  };

  const handleDownloadExcel = async () => {
    if (sales.length !== 0) {
      setDisable(true);
      const visibleData = sales.map((item) => ({
        Party: item.partyId.partyName,
        Sales_Date: item.salesDate.slice(0, 10),
      }));
      try {
        const ws = XLSX.utils.json_to_sheet(visibleData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `sales_report.xlsx`);
      } catch (error) {
        console.error("Error generating Excel file:", error);
      }
      setDisable(false);
      toast.success("XLSX Downloaded!");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Sales Report</h4>
            <h6>Manage your Sales Report</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-path">
                  <button
                    className={`btn btn-filter ${filterShow ? "setclose" : ""}`}
                    onClick={() => setFilterShow(!filterShow)}
                    id="filter_search"
                  >
                    <img src="/img/icons/filter.svg" alt="img" />
                    <span>
                      <img src="/img/icons/closes.svg" alt="img" />
                    </span>
                  </button>
                </div>
                <div className="search-input">
                  <button className="btn btn-searchset">
                    <img src="/img/icons/search-white.svg" alt="img" />
                  </button>
                </div>
              </div>
              <div className="wordset">
                <ul>
                  <li>
                    <button
                      type="button"
                      onClick={generatePDF}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="pdf"
                    >
                      <img src="/img/icons/pdf.svg" alt="img" />
                    </button>
                  </li>
                  <li>
                    <button
                      style={{ background: "none" }}
                      onClick={handleDownloadExcel}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="excel"
                    >
                      <img src="/img/icons/excel.svg" alt="img" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="card"
              id="filter_inputs"
              style={{ display: filterShow ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <input
                          type="date"
                          placeholder="From Date"
                          className="datetimepicker"
                          name="startDate"
                          onChange={handleChange}
                          value={filterInputs.startDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <input
                          type="date"
                          placeholder="To Date"
                          className="datetimepicker"
                          name="endDate"
                          onChange={handleChange}
                          value={filterInputs.endDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <select
                        className="select"
                        onChange={handleChange}
                        name="party"
                        value={filterInputs.party}
                      >
                        <option value={""}>All Party</option>
                        {[...new Set(sales.map((item) => item.party))].map(
                          (party, index) => (
                            <option key={index} value={party}>
                              {party}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <button
                        onClick={() =>
                          setFilterInputs({
                            startDate: "",
                            endDate: "",
                            party: "",
                          })
                        }
                        className="btn btn-filters ms-auto"
                      >
                        <img src="/img/icons/closes.svg" alt="img" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DataTable columns={tableHead} data={filterSearch()} pagination />
          </div>
        </div>
        <Modal show={view} onHide={() => setView(false)} size="xl">
          {purchaseData && (
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Party</label>

                      {/* <ReactSelect styles={customStyles} className="p-0" options={partyOptions} /> */}
                      {/* <SelectSearch options={partyOptions} name="party" search={true}/> */}

                      <strong>{purchaseData.party}</strong>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Sales Date </label>
                      <strong>
                        {" "}
                        {purchaseData.salesDate &&
                          purchaseData.salesDate.slice(0, 10)}{" "}
                      </strong>
                    </div>
                  </div>
                </div>
                <DataTable columns={tableItemHead} data={purchaseData.items} />

                <hr></hr>
                <div className="row d-flex justify-content-end">
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() => setView(false)}
                      className="btn btn-cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default SalesReport;
