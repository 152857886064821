import React, { useContext, useEffect, useState } from "react";
import { ItemContext } from "../Context/ItemContext";
import { VendorContext } from "../Context/VendorContext";
import DataTable from "react-data-table-component";
import config from "../../Config";
import axios from "axios";
import Select from "react-select";
import { PurchaseContext } from "../Context/PurchaseContext";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";
import { StoreContext } from "../Context/StoreContext";
import { GodownContext } from "../Context/GodownContext";

const AddPurchase = () => {

  const { items, getItems } = useContext(ItemContext);
  const [stock, setStock] = useState([]);
  const { logout } = useContext(AuthContext);
  const { vendors } = useContext(VendorContext);
  const { getPurchases } = useContext(PurchaseContext);
  const { stores } = useContext(StoreContext);
  const { godowns } = useContext(GodownContext);
  const [itemList, setItemList] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const token = localStorage.getItem("token");
  useEffect(()=>{
    console.log(token);
  },[token])
  const [selectedItem, setSelectedItem] = useState({
    itemName: "",
    quantity: 0,
    category: "",
    unitPrice: 0,
    gstRate: 0,
    itemId: "",
  });
  const [purchase, setPurchase] = useState({
    vendorId: "",
    items: [],
    totalPrice: 0,
    purchaseDate: "",
    storeId: "",
    godownId: "",
  });

  

  const tableHead = [
    {
      name: "Item Name",
      selector: (row) => row.itemName,
      sortable: true,
    },
    {
      name: "Cetegory",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Unit Price",
      selector: (row) => row.unitPrice,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "GST Rate %",
      selector: (row) => row.gstRate,
      sortable: true,
    },
    {
      name: "Item total Price",
      selector: (row) => row.itemPrice,
      sortable: false,
    },
    {
      name: "Action",
      selector: (row, index) => (
        <>
          {/* <a onClick={() => handleEditClick(row)} className="me-3">
          <img src="/img/icons/edit.svg" alt="img" />
        </a> */}
          <a onClick={() => handleDelete(index)} className="confirm-text">
            <img src="/img/icons/delete.svg" alt="img" />
          </a>
        </>
      ),
      sortable: false,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "quantity") {
      setSelectedItem((prevdata) => ({
        ...prevdata,
        [name]: [value],
      }));
    } else if (name === "items") {
      const valueObj = JSON.parse(value);
      console.log(valueObj);
      setSelectedItem((prevdata) => ({
        ...prevdata,
        itemName: valueObj.itemName,
        categoryId: valueObj.category,
        gstRate: valueObj.gstRate,
        unitPrice: valueObj.unitPrice,
        itemId: valueObj._id,
      }));
    } else if (name === "vendor" || name === "purchaseDate") {
      setPurchase((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };

  const handleAdd = () => {
    if (selectedItem.itemId !== "" && parseInt(selectedItem.quantity) !== 0) {
      const newItem = {
        itemName: selectedItem.itemName,
        quantity: parseInt(selectedItem.quantity),
        categoryId: selectedItem.categoryId,
        gstRate: selectedItem.gstRate,
        unitPrice: selectedItem.unitPrice,
        itemId: selectedItem.itemId,
        itemPrice:
          selectedItem.unitPrice * selectedItem.quantity +
          selectedItem.unitPrice *
            selectedItem.quantity *
            (selectedItem.gstRate / 100),
      };
      setGrandTotal(
        grandTotal + selectedItem.unitPrice * selectedItem.quantity
      );
      const existingItemIndex = itemList.findIndex(
        (item) => item.itemId === newItem.itemId
      );

      if (existingItemIndex !== -1) {
        // If it exists, update its quantity
        const updatedItemList = itemList.map((item, index) =>
          index === existingItemIndex
            ? { ...item, quantity: item.quantity + newItem.quantity }
            : item
        );
        setItemList(updatedItemList);
      } else {
        // If it doesn't exist, add the new item
        setItemList([...itemList, newItem]);
      }
      console.log(itemList);
      setPurchase((prevdata) => ({
        ...prevdata,
        totalPrice: prevdata.totalPrice + newItem.itemPrice,
        items: [...prevdata.items, newItem],
      }));
      setSelectedItem((prevdata) => ({
        ...prevdata,
        quantity: 0,
      }));
      setStock([
        ...stock,
        {
          itemId: newItem.itemId,
          quantity: newItem.quantity,
        },
      ]);
    } else {
      toast.warning("Please select an item and enter a quantity.");
    }
  };
  const handleDelete = (index) => {
    const itemToDelete = itemList[index];

    // Filter out the item to delete from the itemList
    const updatedItemList = itemList.filter((_, i) => i !== index);
    const updatedStock = stock.filter((_, i) => i !== index);

    // Update totalPrice by subtracting the itemPrice of the deleted item
    setPurchase((prevdata) => ({
      ...prevdata,
      totalPrice: prevdata.totalPrice - itemToDelete.itemPrice,
      items: updatedItemList,
    }));

    // Update the itemList state
    setStock(updatedStock);
    setItemList(updatedItemList);
  };

  const handleSubmit = async () => {
    try {
      if (
        purchase.totalPrice > 0 &&
        purchase.purchaseDate !== "" &&
        purchase.vendor !== ""
      ) {
        const res = await axios.post(
          `${config.BASE_URL}/create-purchase`,
          purchase,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (res.status === 200) {
          const stockres = await axios.put(
            `${config.BASE_URL}/stock-update`,
            stock,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (stockres.status === 200) {
            toast.success("Purchase Created Succesfully!");
            getItems();
          }

          getPurchases();
          setItemList([]);
          setPurchase((prevdata) => ({
            ...prevdata,
            totalPrice: 0,
            items: [],
          }));
          setGrandTotal(0);
          setSelectedItem((prevdata) => ({
            ...prevdata,
            quantity: 0,
          }));
        }
      } else {
        toast.warning("Please make sure you have entered date and Items");
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAdd(itemList._id);
    }
  };
  const handleSelectChange = (selectedOption, name) => {
    if (name === "items") {
      const valueObj = JSON.parse(selectedOption.value);
      console.log(valueObj);
      setSelectedItem((prevdata) => ({
        ...prevdata,
        itemName: valueObj.itemName,
        categoryId: valueObj.category,
        gstRate: valueObj.gstRate,
        unitPrice: valueObj.unitPrice,
        itemId: valueObj._id,
      }));
    } else {
      setPurchase((prevdata) => ({
        ...prevdata,
        [name]: selectedOption.value,
      }));
    }
  };

  const storeOptions = stores.map((element) => ({
    value: element._id,
    label: element.storeName,
  }));

  const vendorOptions = vendors.map((element) => ({
    value: element._id,
    label: element.vendorsname,
  }));
  const itemOptions = items.map((element) => ({
    value: JSON.stringify(element),
    label: element.itemName,
  }));
  const godownOptions = godowns.map((element) => ({
    value: element._id,
    label: element.godownName,
  }));

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Create Purchase</h4>
            <h6>Add/Update Purchase</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="col-lg-10  col-12">
                  <div className="form-group">
                    <label>Vendor</label>

                    <Select
                      options={vendorOptions}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "vendorId")
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-10  col-12">
                  <div className="form-group">
                    <label>Store</label>

                    <Select
                      options={storeOptions}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "storeId")
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-10  col-12">
                  <div className="form-group">
                    <label>Godown</label>

                    <Select
                      options={godownOptions}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "godownId")
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-10  col-12">
                  <div className="form-group">
                    <label>Purchase Date </label>
                    <div className="input-groupicon">
                      <input
                        type="date"
                        placeholder="DD-MM-YYYY"
                        onChange={handleChange}
                        value={purchase.purchaseDate}
                        name="purchaseDate"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-10  col-12">
                  <div className="form-group">
                    <label>Item</label>

                    <Select
                      options={itemOptions}
                      onChange={(selectedOption) =>
                        handleSelectChange(selectedOption, "items")
                      }
                    />
                    
                  </div>
                </div>

                <div className="col-lg-11  col-12">
                  <div className="form-group">
                    <label>Quantity</label>
                    <div className="row">
                      <div className="col-lg-10 col-sm-10 col-10">
                        <input
                          type="number"
                          name="quantity"
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          value={selectedItem.quantity}
                        />
                      </div>
                      <div className="col-lg-2 col-sm-2 col-2 ps-0">
                        <div
                          onClick={() => handleAdd(itemList._id)}
                          className="add-icon"
                        >
                          <a>
                            <img src="/img/icons/plus1.svg" alt="img" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-12 overflow-auto"
                style={{ maxHeight: "55vh" }}
              >
                <DataTable columns={tableHead} data={itemList} />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 float-md-right">
                <div className="total-order">
                  <ul>
                    <li className="total">
                      <h4>{"Grand Total (Without GST)"}</h4>
                      <h5>{grandTotal || 0}/-</h5>
                    </li>
                    <li className="total">
                      <h4>Grand Total</h4>
                      <h5>{purchase.totalPrice || 0}/-</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-end">
              <div className="col-lg-12 d-flex justify-content-end">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-submit me-2"
                >
                  Submit
                </button>
                {/* <a href="purchaselist.html" className="btn btn-cancel">
                  Cancel
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPurchase;
