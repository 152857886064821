import Modal from "react-bootstrap/Modal";
import React, { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { StoreContext } from "../Context/StoreContext";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const Store = () => {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const { permission } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    storeName: "",
    manager: "",
    location: "",
  });
  const { stores, createStore, updateStore, deleteStore } =
    useContext(StoreContext);
  // const { contactPerson } = useContext(CategoryContext);
  const tableHead = [
    {
      name: 'SNo',
      selector: (row, index) => index + 1, 
      sortable: false,
      width: '70px',
    },
    {
      name: "Store Name",
      selector: (row) => row.storeName,
      sortable: true,
    },
    {
      name: "Manager",
      selector: (row) => row.manager,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    ...(permission.stores.edit || permission.stores.delete
      ? [
          {
            name: "Action",
            selector: (row) => (
              <>
                {permission.stores.edit && (
                  <a onClick={() => handleEditClick(row)} className="me-3">
                    <img src="/img/icons/edit.svg" alt="edit" />
                  </a>
                )}
                {permission.stores.delete && (
                  <a
                    onClick={() => handleDelete(row._id)}
                    className="confirm-text"
                  >
                    <img src="/img/icons/delete.svg" alt="delete" />
                  </a>
                )}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  const handleDelete = (id) => {
    const isConfirm = window.confirm(
      "Are you sure, you want to delete the Store?"
    );
    if (isConfirm) {
      try {
        deleteStore(id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditClick = (data) => {
    setFormData(data);
    setEditShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    try {
      if (
        formData.storeName !== "" &&
        formData.manager !== "" &&
        formData.location !== ""
      ) {
        createStore(formData);
        setFormData({
          storeName: "",
          manager: "",
          location: "",
        });
        setShow(false);
      } else {
        toast.warning("Enter all Fields!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = (id) => {
    try {
      updateStore(id, formData);
      setFormData({
        storeName: "",
        manager: "",
        location: "",
      });
      setEditShow(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Store List</h4>
            <h6>Manage your Stores</h6>
          </div>
          {permission.stores.create && (
            <div className="page-btn">
              <a
                href="#"
                onClick={() => setShow(true)}
                className="btn btn-added"
              >
                <img src="/img/icons/plus.svg" alt="img" className="me-2" /> Add
                Store
              </a>
            </div>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <DataTable columns={tableHead} data={stores} pagination />
            </div>
          </div>
        </div>

        <Modal size="lg" show={show} onHide={() => setShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Store's Name</label>
                    <input
                      type="text"
                      name="storeName"
                      value={formData.storeName}
                      placeholder="Enter Store's name"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Manager</label>
                    <input
                      type="text"
                      name="manager"
                      value={formData.manager}
                      placeholder="Enter Manager"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Location</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        className="pass-inputs"
                        name="location"
                        value={formData.location}
                        placeholder="Enter Location"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCreate}
                    className="btn btn-submit me-2"
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShow(false);
                      setFormData({
                        storeName: "",
                        manager: "",
                        location: "",
                      });
                    }}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal size="lg" show={editShow} onHide={() => setEditShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Store's Name</label>
                    <input
                      type="text"
                      name="storeName"
                      value={formData.storeName}
                      placeholder="Enter Store's name"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Manager</label>
                    <input
                      type="text"
                      name="manager"
                      value={formData.manager}
                      placeholder="Enter Manager"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Location</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        name="location"
                        value={formData.location}
                        placeholder="Enter Location"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={() => handleUpdate(formData._id)}
                    className="btn btn-submit me-2"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setEditShow(false);
                      setFormData({
                        storeName: "",
                        manager: "",
                        location: "",
                      });
                    }}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Store;
