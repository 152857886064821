import logo from "./logo.svg";
import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "jspdf-autotable";
import Login from "./Components/Login/Login";
import { AuthProvider } from "./Components/Context/AuthContext";
// import Dashboard from './Components/Dashboard/Dashboard';
import PrivateLayout from "./Components/Layout/PrivateLayout";

import CategoryList from "./Components/Stock/CategoryList";

import AddPurchase from "./Components/Purchase/CreatePurchase";
import ImportPurchase from "./Components/Purchase/OldDataUpload";
import PaymentList from "./Components/Payment/PaymentList";
import AddPayment from "./Components/Payment/AddPayment";
import PurchaseOrderReport from "./Components/Report/PurchaseOderReport";
import InventoryReport from "./Components/Stock/InventoryReport";
import InvoiceReport from "./Components/Report/InvoiceReport";
import PurchaseReport from "./Components/Purchase/PurchaseReport";
import VendorReport from "./Components/Report/VendorReport";
import CustomerReport from "./Components/Report/CustomerReport";
import UserList from "./Components/User/UserList";
import RolesAndPermission from "./Components/User/RolesAndPermission";
import GeneralSettings from "./Components/Settings/Configurations";
import Navbar from "./Components/Navbar/Navbar";
import Dashboard from "./Components/Dashboard/Dashboard";
import BillUpload from "./Components/Purchase/BillUpload";
import StockAddition from "./Components/Purchase/StockAddition";
import LevelManagement from "./Components/Stock/LevelManagement";
import CreateVendor from "./Components/Settings/CreateVendor";
import CreateItem from "./Components/Stock/CreateItem";
import PaymentReport from "./Components/Payment/PaymentReport";
import OutstandingPayment from "./Components/Payment/OutstandingPayment";
import HomeLayout from "./Components/Layout/HomeLayout";
import AllNotifications from "./Components/Navbar/AllNotifications";
import ForgotPassword from "./Components/Login/ForgotPassword";
import Permissions from "./Components/User/Permissions";
import { ItemProvider } from "./Components/Context/ItemContext";
import { CategoryProvider } from "./Components/Context/CategoryContext";
import { VendorProvider } from "./Components/Context/VendorContext";
import { PurchaseProvider } from "./Components/Context/PurchaseContext";
import AddSales from "./Components/Sales/AddSales";
import SalesReport from "./Components/Sales/SalesReport";
import { ToastContainer } from "react-toastify";
import CalculatorHome from "./Components/Calculator/CalculatorHome";
import PF from "./Components/Calculator/PF";
import NPS from "./Components/Calculator/NPS";
import Salary from "./Components/Calculator/Salary.";
import IncomeTax from "./Components/Calculator/IncomeTax";
import LoanTenure from "./Components/Calculator/LoanTenure";
import LoanAmount from "./Components/Calculator/LoanAmount";
import LoanEmi from "./Components/Calculator/LoanEmi";
import PPF from "./Components/Calculator/PPF";
import BussinessEmi from "./Components/Calculator/BussinessEmi";
import Gratuity from "./Components/Calculator/Gratuity";
import Hra from "./Components/Calculator/Hra";
import Gst from "./Components/Calculator/Gst";
import SimpleInterest from "./Components/Calculator/SimpleInterest";
import Party from "./Components/Settings/Party";
import Feedback from "./Components/User/Feedback";
import { StoreProvider } from "./Components/Context/StoreContext";
import { PartyProvider } from "./Components/Context/PartyContext";
import { GodownProvider } from "./Components/Context/GodownContext";
import Godown from "./Components/Settings/Godown";
import Store from "./Components/Settings/Store";


function App() {
  return (
    <div className="position-relative" style={{minHeight:'100vh'}}>
    <Router>
      <ToastContainer />
      <AuthProvider>
        <ItemProvider>
          <CategoryProvider>
            <VendorProvider>
              <PurchaseProvider>
                <PartyProvider>
                <GodownProvider>
                <StoreProvider>
                <Routes>
                  <Route path="/" element={<HomeLayout />}>
                    <Route index path="/login" element={<Login />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                  </Route>

                  <Route element={<PrivateLayout />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/calculator" element={<CalculatorHome />} />
                    <Route path="/stock/item-ledger" element={<CreateItem />} />

                    <Route
                      path="/stock/level-management"
                      element={<LevelManagement />}
                    />
                    <Route
                      path="/stock/item-category"
                      element={<CategoryList />}
                    />

                    <Route
                      path="/purchase/stock-addition"
                      element={<StockAddition />}
                    />
                    <Route path="/purchase/create" element={<AddPurchase />} />
                    <Route
                      path="/purchase/old-data-upload"
                      element={<ImportPurchase />}
                    />
                    <Route
                      path="/purchase/bill-upload"
                      element={<BillUpload />}
                    />
                    <Route
                      path="/purchase/reports"
                      element={<PurchaseReport />}
                    />
                    <Route path="/payments/summary" element={<PaymentList />} />
                    <Route path="/payments/entry" element={<AddPayment />} />
                    <Route
                      path="/payments/outstanding"
                      element={<OutstandingPayment />}
                    />
                    <Route
                      path="/payments/reports"
                      element={<PaymentReport />}
                    />
                    <Route
                      path="/purchaseorderreport"
                      element={<PurchaseOrderReport />}
                    />
                    <Route
                      path="/stock/reports"
                      element={<InventoryReport />}
                    />
                    <Route path="/salesreport" element={<SalesReport />} />
                    <Route path="/invoicereport" element={<InvoiceReport />} />
                    <Route path="/supplierreport" element={<VendorReport />} />
                    <Route
                      path="/customerreport"
                      element={<CustomerReport />}
                    />

                    <Route path="/users/list" element={<UserList />} />
                    <Route
                      path="/users/role-permission-create"
                      element={<RolesAndPermission />}
                    />
                    <Route path="/sales/entry" element={<AddSales />} />
                    <Route path="/sales/reports" element={<SalesReport />} />
                    <Route
                      path="/settings/configuration"
                      element={<GeneralSettings />}
                    />
                    <Route path="/vendor/create" element={<CreateVendor />} />
                    <Route path="/party" element={<Party/>} />
                    <Route path="/godown" element={<Godown/>} />

                    <Route path="/store" element={<Store/>} />
                    <Route path="/item/create" element={<CreateItem />} />
                    <Route
                      path="/all-notifications"
                      element={<AllNotifications />}
                    />
                    <Route path="/test" element={<Permissions />} />
                    <Route path="/pf" element={<PF />} />
                    <Route path="/nps" element={<NPS />} />
                    <Route path="/salary" element={<Salary />} />
                    <Route path="/income-tax" element={<IncomeTax />} />
                    <Route path="/loan-tenure" element={<LoanTenure />} />
                    <Route path="/loan-amount" element={<LoanAmount />} />
                    <Route path="/loan-emi" element={<LoanEmi />} />
                    <Route path="/ppf" element={<PPF />} />
                    <Route
                      path="/bussiness-loan-emi"
                      element={<BussinessEmi />}
                    />
                    <Route path="/gratuity" element={<Gratuity />} />
                    <Route path="/hra" element={<Hra />} />
                    <Route path="/gst" element={<Gst />} />
                    <Route
                      path="/simple-interest"
                      element={<SimpleInterest />}
                    />
                    <Route path="/feedback" element={<Feedback />} />
                    
                  </Route>
                </Routes>
                </StoreProvider>
                </GodownProvider>
                </PartyProvider>
              </PurchaseProvider>
            </VendorProvider>
          </CategoryProvider>
        </ItemProvider>
      </AuthProvider>
    </Router>
    
    <div className="position-absolute" style={{bottom:"10px",right:"20px"}}>Made with ❤️ by SoftwareSathi</div>
    </div>
  );
}

export default App;
