import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { CategoryContext } from "../Context/CategoryContext";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const CategoryList = () => {
  const [formData, setFormData] = useState({
    categoryname: "",
    description: "",
  });
  const { permission } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const {
    category,
    updateCategory,
    deleteCategory,
    createCategory,
    getCategory,
  } = useContext(CategoryContext);
  const tableHead = [
    {
      name: 'SNo',
      selector: (row, index) => index + 1, 
      sortable: false,
      width: '70px',
    },
    {
      name: "Category Name",
      selector: (row) => row.categoryname,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: false,
    },
    ...(permission.category.edit || permission.category.delete
      ? [
          {
            name: "Action",
            selector: (row) => (
              <>
                {permission.category.edit && (
                  <a onClick={() => handleEditClick(row)} className="me-3">
                    <img src="/img/icons/edit.svg" alt="img" />
                  </a>
                )}
                {permission.category.delete && (
                  <a
                    onClick={() => handleDelete(row._id)}
                    className="confirm-text"
                  >
                    <img src="/img/icons/delete.svg" alt="img" />
                  </a>
                )}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  const handleDelete = (id) => {
    try {
      const isConfirm = window.confirm(
        "Are you sure, you want to delete the category?"
      );
      if (isConfirm) deleteCategory(id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = (data) => {
    try {
      setEditShow(true);
      setFormData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = (id) => {
    try {
      updateCategory(id, formData);
      setFormData({
        categoryname: "",
        description: "",
      });
      setEditShow(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpload = () => {
    try {
      if (formData.categoryname !== "" && formData.description !== "") {
        createCategory(formData);
        setFormData({
          categoryname: "",
          description: "",
        });
        setShow(false);
      } else {
        toast.warning("Enter all fields!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Product Category list</h4>
            <h6>View/Search product Category</h6>
          </div>
          {permission.category.create && (
            <div className="page-btn">
              <a onClick={() => setShow(true)} className="btn btn-added">
                <img src="/img/icons/plus.svg" className="me-1" alt="img" />
                Add Category
              </a>
            </div>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            <DataTable columns={tableHead} data={category} pagination />
          </div>
        </div>

        <Modal size="lg" show={show} onHide={() => setShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Category Name</label>
                    <input
                      type="text"
                      name="categoryname"
                      value={formData.categoryname}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        className="pass-inputs"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    className="btn btn-submit me-2"
                    onClick={handleUpload}
                  >
                    Create
                  </button>
                  <button
                    className="btn btn-cancel"
                    onClick={() => {
                      setFormData({
                        categoryname: "",
                        description: "",
                      });
                      setShow(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal size="lg" show={editShow} onHide={() => setEditShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Category Name</label>
                    <input
                      type="text"
                      name="categoryname"
                      value={formData.categoryname}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        className="pass-inputs"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    className="btn btn-submit me-2"
                    onClick={() => handleUpdate(formData._id)}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-cancel"
                    onClick={() => {
                      setFormData({
                        categoryname: "",
                        description: "",
                      });
                      setEditShow(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CategoryList;
