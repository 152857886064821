import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CategoryContext } from "../Context/CategoryContext";
import { ItemContext } from "../Context/ItemContext";
import { PurchaseContext } from "../Context/PurchaseContext";
import { VendorContext } from "../Context/VendorContext";
import axios from "axios";
import config from "../../Config";

const Dashboard = () => {
  const { getCategory } = useContext(CategoryContext);
  const { getItems } = useContext(ItemContext);
  const { getPurchases } = useContext(PurchaseContext);
  const { getVendors } = useContext(VendorContext);
  const token = localStorage.getItem("token");
  const [dashboardData, setDashboardData] = useState(null);

  const fetchData = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/dashboard`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if(res.status === 200){
        setDashboardData(res.data.data);
      }
    } catch (error) {}
  };
  

  

  useEffect(() => {
    getCategory();
    getItems();
    getPurchases();
    getVendors();
    fetchData();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          {/* Map Implementation |||||||||||||| */}
          <div className="col-lg-3 col-sm-6 col-12">
            <Link to="/vendor/create">
              <div className="dash-widget">
                <div className="dash-widgetimg">
                  <span>
                    <img src="/img/icons/dashVendor.svg" alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    
                    <span className="counters" data-count="307144.00">
                      {dashboardData ? dashboardData.vendorsCount : '0'}
                    </span>
                  </h5>
                  <h6>Total Vendors</h6>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-lg-2 col-sm-6 col-12">
            <Link to="/stock/item-ledger">
              <div className="dash-widget dash1">
                <div className="dash-widgetimg">
                  <span>
                    <img src="/img/icons/dashItem.svg" alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                  
                    <span className="counters" data-count="4385.00">
                    {dashboardData ? dashboardData.itemsCount : '0'}
                    </span>
                  </h5>
                  <h6>Total Items</h6>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-sm-6 col-12">
            <Link to="/purchase/create">
              <div className="dash-widget dash2">
                <div className="dash-widgetimg">
                  <span>
                    <img src="/img/icons/dash3.svg" alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <span className="counters" data-count="385656.50">
                    {dashboardData ? dashboardData.purchasesCount : '0'}
                    </span>
                  </h5>
                  <h6>Total Purchases</h6>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-2 col-sm-6 col-12">
            <Link to="/sales/entry">
              <div className="dash-widget dash3">
                <div className="dash-widgetimg">
                  <span>
                    <img src="/img/icons/dash4.svg" alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <span className="counters" data-count="40000.00">
                    {dashboardData ? dashboardData.salesCount : '0'}
                    </span>
                  </h5>
                  <h6>Total Sales</h6>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <Link to="/payments/entry">
              <div className="dash-widget dash1">
                <div className="dash-widgetimg">
                  <span>
                  <img src="/img/icons/dash2.svg" alt="img" />
                  </span>
                </div>
                <div className="dash-widgetcontent">
                  <h5>
                    <span className="counters" data-count="40000.00">
                    {dashboardData ? dashboardData.paymentsCount : '0'}
                    </span>
                  </h5>
                  <h6>Total Payments</h6>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-12 col-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">Purchase</h5>
                
              </div>
              <div className="card-body">
                <div id="sales_charts">
                 {dashboardData && <img src={dashboardData.pieChart} alt="pieChart"/>}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">Monthly Sales</h5>
                
              </div>
              <div className="card-body">
                <div id="sales_charts">
              {dashboardData &&  <img src={dashboardData.barChart} alt="barChart"/>}
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default Dashboard;
