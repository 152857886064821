import Modal from "react-bootstrap/Modal";
import React, { useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { PartyContext } from "../Context/PartyContext";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const Party = () => {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const { permission } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    partyName: "",
    contactPerson: "",
    contactNumber: "",
    email: "",
    address: "",
    gstNumber: "",
  });
  const { parties, createParty, updateParty, deleteParty } =
    useContext(PartyContext);
  // const { contactPerson } = useContext(CategoryContext);
  const tableHead = [
    {
      name: 'SNo',
      selector: (row, index) => index + 1, 
      sortable: false,
      width: '70px',
    },
    {
      name: "Party Name",
      selector: (row) => row.partyName,
      sortable: true,
    },
    {
      name: "Contact Person",
      selector: (row) => row.contactPerson,
      sortable: true,
    },
    {
      name: "Phone No.",
      selector: (row) => row.contactNumber,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "GST Number",
      selector: (row) => row.gstNumber,
      sortable: false,
    },
    ...(permission.parties.edit || permission.parties.delete
      ? [
          {
            name: "Action",
            selector: (row) => (
              <>
                {permission.parties.edit && (
                  <a onClick={() => handleEditClick(row)} className="me-3">
                    <img src="/img/icons/edit.svg" alt="edit" />
                  </a>
                )}
                {permission.parties.delete && (
                  <a
                    onClick={() => handleDelete(row._id)}
                    className="confirm-text"
                  >
                    <img src="/img/icons/delete.svg" alt="delete" />
                  </a>
                )}
              </>
            ),
            sortable: false,
          },
        ]
      : []),
  ];

  const handleDelete = (id) => {
    const isConfirm = window.confirm(
      "Are you sure, you want to delete the Party?"
    );
    if (isConfirm) {
      try {
        deleteParty(id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditClick = (data) => {
    setFormData(data);
    setEditShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[+]?\d*$/;
    if (name === "contactNumber") {
      if (regex.test(value)) {
        setFormData((prevdata) => ({
          ...prevdata,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
    
  };

  const handleCreate = () => {
    try {
      if (
        formData.partyName !== "" &&
        formData.contactPerson !== "" &&
        formData.contactNumber !== "" &&
        formData.email !== "" &&
        formData.address !== "" &&
        formData.gstNumber !== ""
      ) {
        createParty(formData);
        setFormData({
          partyName: "",
          contactPerson: "",
          contactNumber: "",
          email: "",
          address: "",
          gstNumber: "",
        });
        setShow(false);
      } else {
        toast.warning("Enter all Fields!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = (id) => {
    try {
      updateParty(id, formData);
      setFormData({
        partyName: "",
        contactPerson: "",
        contactNumber: "",
        email: "",
        address: "",
        gstNumber: "",
      });
      setEditShow(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Party List</h4>
            <h6>Manage your Parties</h6>
          </div>
          {permission.parties.create && (
            <div className="page-btn">
              <a
                href="#"
                onClick={() => setShow(true)}
                className="btn btn-added"
              >
                <img src="/img/icons/plus.svg" alt="img" className="me-2" /> Add
                Party
              </a>
            </div>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <DataTable columns={tableHead} data={parties} pagination />
            </div>
          </div>
        </div>

        <Modal size="lg" show={show} onHide={() => setShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Party's Name</label>
                    <input
                      type="text"
                      name="partyName"
                      value={formData.partyName}
                      placeholder="Enter Party's name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Contact Person</label>
                    <input
                      type="text"
                      name="contactPerson"
                      value={formData.contactPerson}
                      placeholder="Enter Contact Person's Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="Enter Email"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Mobile</label>
                    <input
                      type="phone"
                      name="contactNumber"
                      value={formData.contactNumber}
                      placeholder="Enter Phone Number"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Address</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        className="pass-inputs"
                        name="address"
                        value={formData.address}
                        placeholder="Enter Address"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>GST Number</label>
                    <div className="pass-group">
                      <input
                        type="text"
                        name="gstNumber"
                        value={formData.gstNumber}
                        placeholder="Enter GSTNumber"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={handleCreate}
                    className="btn btn-submit me-2"
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setShow(false);
                      setFormData({
                        partyName: "",
                        contactPerson: "",
                        contactNumber: "",
                        email: "",
                        address: "",
                        gstNumber: "",
                      });
                    }}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal size="lg" show={editShow} onHide={() => setEditShow(false)}>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Party's Name</label>
                    <input
                      type="text"
                      name="partyName"
                      value={formData.partyName}
                      placeholder="Enter Party's name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Contact Person</label>
                    <input
                      type="text"
                      name="contactPerson"
                      value={formData.contactPerson}
                      placeholder="Enter Contact Person's Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="Enter Email"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Mobile</label>
                    <input
                      type="phone"
                      name="contactNumber"
                      value={formData.contactNumber}
                      placeholder="Enter Phone Number"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>Address</label>
                    <div className="pass-group">
                      <textarea
                        type="text"
                        name="address"
                        value={formData.address}
                        placeholder="Enter Address"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="form-group">
                    <label>GST Number</label>
                    <div className="pass-group">
                      <input
                        type="text"
                        name="gstNumber"
                        value={formData.gstNumber}
                        placeholder="Enter Gst Number"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    onClick={() => handleUpdate(formData._id)}
                    className="btn btn-submit me-2"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setEditShow(false);
                      setFormData({
                        partyName: "",
                        contactPerson: "",
                        contactNumber: "",
                        email: "",
                        address: "",
                        gstNumber: "",
                      });
                    }}
                    className="btn btn-cancel"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Party;
