import React, { useContext, useState } from "react";
import { PurchaseContext } from "../Context/PurchaseContext";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { VendorContext } from "../Context/VendorContext";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";


const PurchaseReport = () => {
  const [filterShow, setFilterShow] = useState(false);
  const [filterInputs, setFilterInputs] = useState({
    startDate: "",
    endDate: "",
    vendor: "",
  });
  const { vendors } = useContext(VendorContext);
  const { purchases } = useContext(PurchaseContext);
  const [purchaseData, setPurchaseData] = useState([]);
  const [view, setView] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterInputs((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const filterSearch = () => {
    const startDate = filterInputs.startDate
      ? new Date(filterInputs.startDate)
      : null;
    const endDate = filterInputs.endDate
      ? new Date(filterInputs.endDate)
      : null;
    if (startDate && endDate && filterInputs.vendor !== "") {
      return purchases.filter((item) => {
        const itemDate = new Date(item.purchaseDate);

        return (
          (!startDate || itemDate >= startDate) &&
          (!endDate || itemDate <= endDate) &&
          item.vendor === filterInputs.vendor
        );
      });
    } else if (startDate && endDate) {
      return purchases.filter((item) => {
        const itemDate = new Date(item.purchaseDate);

        return (
          (!startDate || itemDate >= startDate) &&
          (!endDate || itemDate <= endDate)
        );
      });
    } else if (filterInputs.vendor !== "") {
      return purchases.filter((item) => {
        return item.vendor === filterInputs.vendor;
      });
    } else {
      return purchases;
    }
  };

  const tableItemHead = [
    {
      name: 'SNo',
      selector: (row, index) => index + 1, 
      sortable: false,
      width: '70px',
    },
    {
      name: "Item Name",
      selector: (row) => row.itemId.itemName,
      sortable: true,
    },
    {
      name: "Cetegory",
      selector: (row) => row.categoryId._id,
      sortable: true,
    },
    {
      name: "Unit Price",
      selector: (row) => row.unitPrice,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "GST Rate %",
      selector: (row) => row.gstRate,
      sortable: true,
    },
    {
      name: "Item total Price",
      selector: (row) => row.itemPrice,
      sortable: false,
    },
  ];

  const tableHead = [
    {
      name: "Purchase ID",
      selector: (row) => row._id,
      sortable: true,
    },
    {
      name: "Vendor",
      selector: (row) => row.vendorId.vendorsname,
      sortable: true,
    },
    {
      name: "Purchase Date",
      selector: (row) => row.purchaseDate && row.purchaseDate.slice(0, 10),
      sortable: true,
    },
    {
      name: "Total Price",
      selector: (row) => row.totalPrice,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <button type="button" onClick={() => handleView(row)}>
          <img src="/img/icons/view.svg" alt="img" />
        </button>
      ),
      sortable: true,
    },
  ];

  const handleView = (row) => {
    setView(true);
    setPurchaseData(row);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    setDisable(true);
    const columns = ["Purchase Id", "Vendor", "Purchase Date", "Grand Total"];

    const rows = purchases.map((data) => [
      data._id,
      data.vendorId.vendorsname,
      data.purchaseDate && data.purchaseDate.slice(0, 10),
      data.totalPrice,
    ]);

    doc.text("Purchase Report", 14, 15);
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
      theme: "grid",
    });
    doc.save("purchase_report.pdf");
    setDisable(false);
    toast.success("PDF Downloaded!");
  };

  const handleDownloadExcel = async () => {
    if (purchases.length !== 0) {
      setDisable(true);
      const visibleData = purchases.map((item) => ({
        Purchase_ID: item._id,
        Vendor: item.vendorId.vendorsname,
        Purchase_Date: item.purchaseDate && item.purchaseDate.slice(0, 10),
        Total_Price: item.totalPrice,
      }));
      try {
        const ws = XLSX.utils.json_to_sheet(visibleData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `purchase_report.xlsx`);
      } catch (error) {
        console.error("Error generating Excel file:", error);
      }
      setDisable(false);
      toast.success("XLSX Downloaded!");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Purchase Report</h4>
            <h6>Manage your Purchase Report</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-path">
                  <button
                    className={`btn btn-filter ${filterShow ? "setclose" : ""}`}
                    onClick={() => setFilterShow(!filterShow)}
                    id="filter_search"
                  >
                    <img src="/img/icons/filter.svg" alt="img" />
                    <span>
                      <img src="/img/icons/closes.svg" alt="img" />
                    </span>
                  </button>
                </div>
                <div className="search-input">
                  <button className="btn btn-searchset">
                    <img src="/img/icons/search-white.svg" alt="img" />
                  </button>
                </div>
              </div>
              <div className="wordset">
                <ul>
                  <li>
                    <button
                      type="button"
                      disabled={disable}
                      onClick={generatePDF}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="pdf"
                    >
                      <img src="/img/icons/pdf.svg" alt="img" />
                    </button>
                  </li>
                  <li>
                    <button
                      style={{ background: "none" }}
                      disabled={disable}
                      onClick={handleDownloadExcel}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="excel"
                    >
                      <img src="/img/icons/excel.svg" alt="img" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="card"
              id="filter_inputs"
              style={{ display: filterShow ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <input
                          type="date"
                          placeholder="From Date"
                          className="datetimepicker"
                          name="startDate"
                          onChange={handleChange}
                          value={filterInputs.startDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <div className="input-groupicon">
                        <input
                          type="date"
                          placeholder="To Date"
                          className="datetimepicker"
                          name="endDate"
                          onChange={handleChange}
                          value={filterInputs.endDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="form-group">
                      <select
                        className="select"
                        onChange={handleChange}
                        name="vendor"
                        value={filterInputs.vendor}
                      >
                        <option value={""}>All Vendors</option>
                        {vendors &&
                          vendors.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.vendorsname}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-1 col-sm-6 col-12 ms-auto">
                    <div className="form-group">
                      <button
                        onClick={() =>
                          setFilterInputs({
                            startDate: "",
                            endDate: "",
                            vendor: "",
                          })
                        }
                        className="btn btn-filters ms-auto"
                      >
                        <img src="/img/icons/closes.svg" alt="img" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <DataTable columns={tableHead} data={filterSearch()} pagination />
          </div>
        </div>
        <Modal show={view} onHide={() => setView(false)} size="xl">
          {purchaseData && (
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Vendor</label>

                      {/* <ReactSelect styles={customStyles} className="p-0" options={vendorOptions} /> */}
                      {/* <SelectSearch options={vendorOptions} name="vendor" search={true}/> */}

                      <strong>{purchaseData.vendor}</strong>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Purchase Date </label>
                      <strong>
                        {" "}
                        {purchaseData.purchaseDate &&
                          purchaseData.purchaseDate.slice(0, 10)}{" "}
                      </strong>
                    </div>
                  </div>
                </div>
                <DataTable columns={tableItemHead} data={purchaseData.items} />

                <div className="row">
                  <div className="col-lg-12 float-md-right">
                    <div className="total-order">
                      <ul>
                        <li className="total">
                          <h4>Grand Total</h4>
                          <h5>{purchaseData.totalPrice || 0}/-</h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-end">
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() => setView(false)}
                      className="btn btn-cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default PurchaseReport;
